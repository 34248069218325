import React, { useState, useContext } from 'react';

const PopupManagerContext = React.createContext();

export function usePopupManager() {
    return useContext(PopupManagerContext);
}

export function PopupManager({ children }) {
    const [popupStack, setPopupStack] = useState([])

    const push = (component) => {
        setPopupStack([...popupStack, component]);
    }

    const popPopup = () => {
        setPopupStack([...popupStack].splice(popupStack.length - 1, 1))
    }

    const showPopup = async (component, props) => {
		return new Promise((resolve) => {
			push({
				component, 
                props: {
					...props,
					key: `popup_${popupStack.length}`,
					onClose: (result) => {
						popPopup();
						resolve(result);
                        if(props?.onClose)
                            props.onClose();
					},
					isOpen: true,
				}
            })
        });
	}

    return (
        <PopupManagerContext.Provider value={{ showPopup, popPopup}}>
            {
                popupStack.map(popup => 
                    React.createElement(popup.component, popup.props)    
                )
            }
            { 
                children 
            }
        </PopupManagerContext.Provider>
    )
}