import React, { useEffect } from 'react';
import Style from './PopupContainer.module.css';
import _ from 'lodash';

const PopupContainer = ({
    children,
    isOpen,
    width,
    backdropColor,
    disableClosing,
    onClose
}) => {
    const [animationRender, setAnimationRender] = React.useState(true);

    useEffect(() => {
        setAnimationRender(false);
    }, [])

    const handleBackdropClick = () => {
        if(disableClosing)
            return;
            
        setAnimationRender(true);
        setTimeout(
            () => {
                if(_.isFunction(onClose))
                    onClose();
            }, 
            200
        );
    }

    const handleContentContainerClick = (event) => {
        event.stopPropagation();
    }

    return (
        <div 
            className={
                !isOpen || (animationRender && isOpen) ?
                Style.backdrop + ' ' + Style.backdropHidden :
                Style.backdrop
            } 
            style={{
                backgroundColor: backdropColor
            }}
            onClick={handleBackdropClick}>
            <div 
                className={
                    !isOpen || (animationRender && isOpen) ?
                    Style.container + ' ' + Style.containerHidden :
                    Style.container
                }
                style={{
                    width: width
                }}
                onClick={handleContentContainerClick}>

                { children }

            </div>
        </div>
    );
}

export default PopupContainer;